export const ingredientTags = [
  'pork',
  'beef',
  'lamb',
  'crustaceans',
  'fish',
  'game',
  'gluten-free',
  'vegan',
  'vegetarian',
  'meat free',
  'molluscs',
  'poultry',
];
export const foodTypeTags = ['gluten-free', 'vegan', 'vegetarian', 'meat free', 'spicy'];

export const filterTags = [
  'Bundles',
  'Meat & Fish',
  'Free From',
  'Vegetarian',
  'Kids',
  'Sides',
  'Desserts',
] as const;

export const foodFilterDictionary = {
  'Meat & Fish': ['pork', 'beef', 'lamb', 'game', 'fish', 'crustaceans', 'molluscs', 'poultry'],
  'Free From': ['gluten-free', 'vegan', 'meat free'],
  Vegetarian: ['vegetarian', 'vegan'],
  Kids: ['section-kids'],
  Sides: ['section-accompaniments'],
  Desserts: ['section-desserts'],
  Bundles: ['section-bundles'],
  'Mix & Match': ['section-mix-and-match'],
};

export type KeyOfFoodDictionary = keyof typeof foodFilterDictionary;

export const sectionTitles = {
  'section-specials': "This Week's Specials",
  'section-favourites': 'Customer Favourites',
  'section-vegetarian': 'Vegetarian',
  'section-kids': 'Kids',
  'section-accompaniments': 'Sides',
  'section-desserts': 'Desserts',
  'section-bundles': 'Bundles',
  'section-mix-and-match': 'Mix & Match',
};

export type KeyOfSectionTitles = keyof typeof sectionTitles;

export const sidesLocalstorageKey = 'sides_desserts_key';

export const MINIMUM_MENU_VALUE = 10;

export const pastaNowUpsellLocalstorageKey = 'pasta_now_upsell_key';
