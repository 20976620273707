import { BasketItem } from '@pasta-evangelists/pasta-types';
import useWeeklyMenuStore from '@/stores/weeklyMenusStore';
import { formatToString } from '@/utils/money';
import { acceptHMRUpdate, defineStore } from 'pinia';
import dayjs from 'dayjs';
import { GIFT_BASKET_STORE_KEY } from '@/constants/utils';
import { DataLayerCheckoutProductGA4, addToCartEvent } from '@/utils/analytics';
import { getProductCategoriesFromTags, getSectionTitleFromTags } from '@/utils';

interface GiftBasketStoreState {
  items: BasketItem[];
  minimumSpend: number;
}

export const serializeGiftBasketToLocalStorage = (
  state: GiftBasketStoreState
): Partial<GiftBasketStoreState> => {
  const { items, minimumSpend } = state;
  return {
    items,
    minimumSpend,
  };
};

const loadGiftBasketFromStorage = () => {
  const localStorageState = localStorage.getItem(GIFT_BASKET_STORE_KEY);
  if (localStorageState) {
    const parsedState = JSON.parse(localStorageState);

    const { updatedAt, data } = parsedState;
    const isNotExpired = dayjs(updatedAt).isAfter(dayjs().subtract(1, 'week'));
    if (isNotExpired) {
      return data;
    } else {
      localStorage.removeItem(GIFT_BASKET_STORE_KEY);
    }
    return null;
  }
  return null;
};

const useGiftBasketStore = defineStore({
  id: 'giftBasketStore',
  state(): GiftBasketStoreState {
    const savedState = loadGiftBasketFromStorage() || {};
    return {
      items: [],
      minimumSpend: 10,
      ...savedState,
    };
  },
  getters: {
    isValid(): boolean {
      return (
        this.items
          .filter(item => !item.attributes.isAddon)
          .reduce(
            (acc, item) => acc + item.attributes.quantity * parseFloat(item.attributes.price),
            0
          ) >= this.minimumSpend
      );
    },
    totalPrice(): number {
      return this.items.reduce(
        (acc, item) => acc + parseFloat(item.attributes.price) * item.attributes.quantity,
        0
      );
    },
    totalPriceInPounds(): string {
      return formatToString({ price: this.totalPrice });
    },
    itemsInMenus(): Record<string, BasketItem[]> {
      const weeklyMenuStore = useWeeklyMenuStore();
      const week = weeklyMenuStore.availableWeeks ? weeklyMenuStore.availableWeeks[0] : '';
      return { [week]: this.items };
    },
  },
  actions: {
    addItem(productVariantId: string, source?: string, quantity?: number) {
      const weeklyMenuStore = useWeeklyMenuStore();
      const product = weeklyMenuStore.productVariants?.[productVariantId];

      if (!product) return;

      const tags = weeklyMenuStore.productVariants?.[productVariantId]?.tags ?? [];
      const category = getProductCategoriesFromTags(
        tags
      ) as DataLayerCheckoutProductGA4['item_category'];
      const category2 = getSectionTitleFromTags(tags);

      this.items.push({
        id: productVariantId,
        type: 'basket_item',
        attributes: {
          discount: '',
          imageUrl: product.featuredImageUrl,
          isDouble: product.isDouble,
          price: product.price,
          productVariantExtId: productVariantId,
          productVariantId: parseInt(productVariantId),
          quantity: quantity || 1,
          title: product.title,
          productId: parseInt(productVariantId),
          tags: product.tags,
          type: 'OneOffBasketItem',
          mealWeek: '',
        },
      });

      const cartData = {
        value: parseFloat(product.price),
        add_to_cart_source: source,
        add_to_cart_menu_week: undefined,
        menu_type: 'One-off',
        fulfilment_type: 'delivery',
      };
      addToCartEvent(cartData, [
        {
          item_id: productVariantId.toString(),
          item_name: product.title,
          price: parseFloat(product.price),
          quantity: quantity || 1,
          item_brand: 'Shopify',
          item_variant: product.isDouble ? 'Grande' : 'Single',
          item_category: category,
          item_category2: category2,
        },
      ]);
    },
    increment(productVariantId: string) {
      const foundAtIndex = this.items.findIndex(item => item.id === productVariantId);

      if (foundAtIndex >= 0) {
        this.items[foundAtIndex].attributes.quantity++;
      }
    },
    decrement(productVariantId: string) {
      const foundAtIndex = this.items.findIndex(item => item.id === productVariantId);

      if (foundAtIndex >= 0) {
        if (this.items[foundAtIndex].attributes.quantity > 1) {
          this.items[foundAtIndex].attributes.quantity--;
          return;
        }
        this.items.splice(foundAtIndex, 1);
      }
    },
    getQuantity(productVariantId: string) {
      const foundAtIndex = this.items.findIndex(item => item.id === productVariantId);
      if (foundAtIndex >= 0) {
        return this.items[foundAtIndex].attributes.quantity;
      }
      return 0;
    },
    loadFromLocalstorage() {
      const result = loadGiftBasketFromStorage();
      if (result) {
        this.$patch(state => {
          state = { ...result };
          return state;
        });
      }
    },
    resetStore() {
      localStorage.removeItem(GIFT_BASKET_STORE_KEY);
      this.$reset();
    },
  },
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useGiftBasketStore, import.meta.hot));

export default useGiftBasketStore;
