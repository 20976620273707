import dayjs from 'dayjs';
import { useQueryClient } from '@tanstack/vue-query';
import { defineStore } from 'pinia';
import { isToday } from '@/utils/date';
import { DataLayerCheckoutProductGA4, addToCartEvent, trackCustomEvent } from '@/utils/analytics';
import {
  RestaurantTimeslot,
  TakeawayBasketItem,
  TakeawayMenuProduct,
} from '@pasta-evangelists/pasta-types';
import useGetBasket from '@/api/queries/pensa/useGetBasket';
import {
  AddToCartParams,
  NormalizedTakeawayBasketItem,
  TakeawayBasketItemWithModifiers,
} from '@/types/pastanow';
import useAddToBasket from '@/api/mutations/basket/useAddItem';
import useGetDeliveryEstimation from '@/api/queries/pensa/useGetDeliveryEstimation';
import useUpdateTakeawayBasket from '@/api/mutations/pensa/updateTakeawayBasket';
import usePastaNowMenu from './pastaNowMenuStore';
import useDeleteBasket from '@/api/mutations/pensa/deleteBasket';
import { queries } from '@/api/queries';
import useBasketHelper from '@/composables/useBasketHelper';
import useBasketFetcher from '@/composables/useBasketFetcher';
import useGetRestaurant from '@/api/queries/pensa/useGetRestaurant';
import useRemoveItem from '@/api/mutations/basket/useRemoveItem';
import useUpdateQuantity from '@/api/mutations/basket/useUpdateQuantity';

const usePastaNowBasket = defineStore('pastaNowBasket', () => {
  const queryClient = useQueryClient();
  const { basketId } = useBasketFetcher('TakeawayBasket');
  const { data: basket, isFetching: fetchingBasket } = useGetBasket(basketId);

  const addToBasketMutation = useAddToBasket();
  const changeQuantityMutation = useUpdateQuantity('TakeawayBasket');
  const removeItemMutation = useRemoveItem('TakeawayBasket');
  const updateBasketMutation = useUpdateTakeawayBasket();
  const deleteBasketMutation = useDeleteBasket();

  const basketHelper = useBasketHelper({ basketId, basket });

  const isMutating = computed(
    () =>
      addToBasketMutation.isPending.value ||
      changeQuantityMutation.isPending.value ||
      removeItemMutation.isPending.value ||
      updateBasketMutation.isPending.value
  );

  const minimumCheckoutValue = computed(() => {
    if (!basketId.value || !basket.value?.basket) return null;
    return parseFloat(basketHelper.basketRules.value.minimumSpend);
  });

  const restaurantId = computed(() => {
    if (!basketId.value || !basket.value) return null;
    const restaurantId = basket.value.basket?.[basketId.value]?.relationships.restaurant?.data?.id;
    return restaurantId || null;
  });

  const { data: restaurant } = useGetRestaurant(restaurantId);

  const restaurantName = computed(() => {
    if (!restaurant.value) return '';
    return restaurant.value.attributes.site;
  });

  const restaurantAddress = computed(() => {
    if (!restaurant.value) return '';
    return `${restaurant.value.attributes.address1}  ${
      restaurant.value.attributes.address2 ? restaurant.value.attributes.address2 : ''
    }, ${restaurant.value.attributes.city}, ${restaurant.value.attributes.postcode}`;
  });

  watch(
    basketId,
    async newbasketId => {
      if (newbasketId) {
        trackCustomEvent('klaviyo_cart_id', {
          pasta_now_cart_id: newbasketId,
        });
      }
    },
    { immediate: true }
  );

  const hasEstimatedFulfillmentWindowData = computed(() => basketETA.value);

  const timeslot = computed<RestaurantTimeslot | null>(() => {
    if (!basketId.value || !basket.value?.basket) return null;
    if (basket.value.basket[basketId.value].attributes.asap)
      return {
        startTime: 'asap',
        endTime: 'asap',
      };
    if (
      !basket.value.basket[basketId.value].attributes.timeSlot ||
      !basket.value.basket[basketId.value].attributes.timeSlotEndTime
    )
      return null;
    return {
      startTime: basket.value.basket[basketId.value].attributes.timeSlot!,
      endTime: basket.value.basket[basketId.value].attributes.timeSlotEndTime!,
    };
  });

  const fulfillmentType = computed(() => {
    if (!basketId.value || !basket.value?.basket) return null;
    return basket.value.basket[basketId.value].attributes.fulfillmentType;
  });

  const deliveryDate = computed(() => {
    if (!basketId.value || !basket.value?.basket) return null;
    return basket.value.basket[basketId.value].attributes.deliveryDate;
  });

  const hasTableNumbers = computed(() => restaurant.value?.attributes.hasTableNumbers);

  const tableNumber = computed(() => {
    if (!basketId.value || !basket.value?.basket) return null;
    return basket.value.basket[basketId.value].attributes.tableNumber;
  });

  const estimatedDeliveryWindow = computed(() => {
    if (!timeslot.value) return '15 - 30 mins';
    if (timeslot.value.startTime === 'asap') {
      if (basketETA.value?.minTimeToDeliver && basketETA.value?.maxTimeToDeliver) {
        return `${basketETA.value?.minTimeToDeliver} - ${basketETA.value?.maxTimeToDeliver} mins`;
      }
      if (basketETA.value)
        return `${basketETA.value.prepTime + basketETA.value.etaDelay + 15} - ${
          basketETA.value.prepTime + basketETA.value.etaDelay + +30
        } mins`;
      return '15 - 30 mins';
    }
    const deliveryStart = dayjs(timeslot.value.startTime).format('HH:mm');
    const deliveryEnd = dayjs(timeslot.value.endTime).format('HH:mm');
    const deliveryDay = dayjs(deliveryDate.value);

    const day = isToday(deliveryDay.format('YYYY-MM-DD')) ? '' : dayjs(deliveryDay).format('ddd');
    return `${day ? day + ', ' : ''}${deliveryStart}-${deliveryEnd}`;
  });

  const totalBasketQuantityIncludingModifiers = computed(() => {
    if (!basket.value?.takeawayBasketItem) return 0;
    return Object.values(basket.value.takeawayBasketItem).reduce((acc, curr) => {
      if (curr.relationships.parent.data) return acc;
      const modifierQuantity = curr.relationships.subItems.data.reduce((acc, curr) => {
        const modifier = basket.value!.takeawayBasketItem![curr.id];
        return acc + modifier.attributes.quantity;
      }, 0);
      return acc + curr.attributes.quantity * (modifierQuantity + 1);
    }, 0);
  });

  const address = computed(() => {
    if (!basketId.value || !basket.value?.basket) return null;
    return basket.value.basket[basketId.value].attributes.address;
  });

  const addressString = computed(() => {
    if (!address.value) return '';
    if (address.value.address1 === 'ignore_me') return address.value.zip;

    const address1 = address.value.address1 ? address.value.address1 + ', ' : '';
    const address2 = address.value.address2 ? address.value.address2 + ', ' : '';
    const city = address.value.city ? address.value.city + ', ' : '';

    return `${address1}${address2}${city}${address.value.zip || ''}`;
  });

  const customerName = computed(() => {
    if (!basketId.value || !basket.value?.basket) return null;
    if (!address.value?.firstName || !address.value?.lastName) return null;
    return {
      firstName: address.value.firstName!,
      lastName: address.value.lastName!,
    };
  });

  const isForCollection = computed(() => {
    if (!basketId.value || !basket.value?.basket) return false;
    return basket.value.basket[basketId.value].attributes.fulfillmentType === 'pickup';
  });

  const isDelivery = computed(() => {
    if (!basketId.value || !basket.value?.basket) return false;
    return basket.value.basket[basketId.value].attributes.fulfillmentType === 'delivery';
  });

  const itemsInCart = computed(() => {
    if (!basket.value?.takeawayBasketItem) return null;
    return Object.values(basket.value.takeawayBasketItem)
      .filter(item => !item.relationships.parent.data)
      .reduce((acc, cur) => {
        acc[cur.id] = cur;
        return acc;
      }, {} as Record<string, TakeawayBasketItem>);
  });

  const productQuantityInCart = computed(() => {
    if (!basket.value?.takeawayBasketItem) return null;
    return Object.values(basket.value.takeawayBasketItem).reduce((acc, curr) => {
      const productVariantId = curr.relationships.productVariant.data.id;
      if (!productVariantId || curr.relationships.parent.data) return acc;
      if (!acc[productVariantId]) {
        acc[productVariantId] = curr.attributes.quantity || 0;
        return acc;
      }
      acc[productVariantId] += curr.attributes.quantity || 0;
      return acc;
    }, {} as Record<string, number>);
  });

  const basketQuantity = computed(() => {
    if (!productQuantityInCart.value) return 0;
    return Object.values(productQuantityInCart.value).reduce((acc, curr) => acc + curr, 0);
  });

  const orderItemsFromCart = computed<TakeawayBasketItemWithModifiers[]>(() => {
    if (!basket.value?.takeawayBasketItem || !itemsInCart.value) return [];
    return Object.values(itemsInCart.value).map(item => {
      let appliedModifiers: NormalizedTakeawayBasketItem[] = [];
      if (item.relationships.subItems.data.length) {
        const modifierIds = item.relationships.subItems.data.map(subItem => subItem.id);
        appliedModifiers = modifierIds.map(modifierId => {
          const modifier = basket.value!.takeawayBasketItem![modifierId];
          return { id: modifier.id, ...modifier.attributes };
        });
      }
      return { id: item.id, ...item.attributes, appliedModifiers };
    });
  });

  const undiscountedSubtotal = computed(() => {
    if (!itemsInCart.value) return 0;
    return Object.values(itemsInCart.value).reduce((acc, cur) => {
      acc = acc + parseFloat(cur.attributes.total) + parseFloat(cur.attributes.discount);
      return acc;
    }, 0);
  });

  const discountAmount = computed(() => {
    if (!basketId.value || !basket.value?.basket || !basketHelper.voucher.value) return 0;
    return (
      parseFloat(basket.value.basket[basketId.value].attributes.totalDiscount) ||
      undiscountedSubtotal.value - basketHelper.subtotal.value
    );
  });

  const isValid = computed(() =>
    Boolean(
      basket.value?.takeawayBasketItem &&
        Object.keys(basket.value.takeawayBasketItem).length > 0 &&
        minimumCheckoutValue.value !== null &&
        basketHelper.subtotalBeforeOrderDiscounts.value >= minimumCheckoutValue.value
    )
  );

  const availableVoucherProducts = computed(() => {
    if (!basket.value?.productVariant) return [];
    const pastaNowMenu = usePastaNowMenu();
    return Object.values(basket.value.productVariant).reduce((acc, cur) => {
      const product = pastaNowMenu.getTakeawayProductForProductId(
        cur.relationships.product.data.id
      );
      if (product && !pastaNowMenu.snoozedProductIds.includes(product.id)) acc.push(product);
      return acc;
    }, [] as TakeawayMenuProduct[]);
  });

  const { data: basketETA, refetch } = useGetDeliveryEstimation({
    address: computed(() => address.value),
    restaurantId,
    numberOfItems: totalBasketQuantityIncludingModifiers,
    timeSlot: computed(() => timeslot.value?.startTime || null),
  });

  const addToCart = async (params: AddToCartParams) => {
    const cartData = {
      value: params.totalPrice,
      add_to_cart_source: 'Product modal',
      add_to_cart_menu_week: undefined,
      menu_type: 'Takeaway',
      fulfilment_type: fulfillmentType.value || 'delivery',
    };
    const item: DataLayerCheckoutProductGA4[] = [
      {
        item_id: params.product.attributes.productVariantId.toString(),
        item_name: params.product.attributes.title,
        price: params.totalPrice,
        quantity: params.quantity,
        item_modifiers: params.subItems?.length,
        item_variant: params.isDouble ? 'Grande' : 'Single',
        item_brand: 'Deliverect',
        item_category: params.category,
      },
    ];
    if (!basketId.value) {
      trackCustomEvent('pasta-now-add-to-cart');
      addToCartEvent(cartData, item);
      return;
    } else {
      trackCustomEvent('pasta-now-add-to-cart');
      addToCartEvent(cartData, item);
      addToBasketMutation.mutate(
        {
          productVariantId: params.product.attributes.productVariantId,
          quantity: params.quantity,
          subItems: params.subItems,
          basketId: basketId.value,
        },
        { onSuccess: () => refetch() }
      );
    }
  };

  const increment = async (id: string) => {
    if (itemsInCart.value?.[id] && basketId.value) {
      const oldQuantity = itemsInCart.value[id].attributes.quantity;

      changeQuantityMutation.mutate(
        {
          itemId: id,
          basketId: basketId.value,
          quantity: oldQuantity + 1,
        },
        {
          onSuccess: () => refetch(),
        }
      );
    }
  };

  const decrement = async (id: string) => {
    if (itemsInCart.value?.[id] && basketId.value) {
      const oldQuantity = itemsInCart.value[id].attributes.quantity;
      if (oldQuantity > 1) {
        changeQuantityMutation.mutate(
          {
            itemId: id,
            basketId: basketId.value,
            quantity: oldQuantity - 1,
          },
          {
            onSuccess: () => refetch(),
          }
        );
      } else {
        removeItemMutation.mutate(
          {
            itemId: id,
            basketId: basketId.value,
          },
          {
            onSuccess: () => refetch(),
          }
        );
      }
    }
  };

  const clearBasket = () => {
    if (!basketId.value) return;
    return deleteBasketMutation.mutateAsync(basketId.value, {
      onSuccess: () => {
        queryClient.removeQueries(queries.baskets.byId(basketId));
        queryClient.refetchQueries(queries.baskets.all);
      },
    });
  };

  return {
    ...basketHelper,
    basket,
    basketId,
    estimatedDeliveryTime: basketETA.value,
    itemsInCart,
    productQuantityInCart,
    isMutating,
    fetchingBasket,
    fulfillmentType,
    isForCollection,
    isDelivery,
    isValid,
    discountAmount,
    basketQuantity,
    estimatedDeliveryWindow,
    orderItemsFromCart,
    availableVoucherProducts,
    hasEstimatedFulfillmentWindowData,
    hasTableNumbers,
    restaurantAddress,
    restaurantId,
    restaurantName,
    deliveryDate,
    timeslot,
    address,
    addressString,
    customerName,
    tableNumber,
    addToCart,
    increment,
    decrement,
    clearBasket,
  };
});

export default usePastaNowBasket;
